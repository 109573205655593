.spinner {
    display: none;
}

.spinner-message {
    font-size: 1.3em;
}

.spinner-graphic,
.spinner-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}