@import "../../../vendor/metronic/theme/sass/global/colors";

.page-header.navbar .page-logo .logo-default {
    margin-top: 6px;
    height: 34px;
}

.chat-form {
    margin-top: 0;
    margin-bottom: 30px;

    // == overwrite bootstrap-fileinput/bootstrap-fileinput.css

    .fileinput {
        margin-bottom: 0;
    }
}

@import 'custom/spinner';
@import 'custom/sortable';

.portlet-body img {
    width: 90%;
}

.form-group label {
    font-weight: 600;
    color: map-get( map-get($component-colors, blue-hoki), base );
}

.table-advance thead tr th {
    font-weight: 600;
}

.content.wide {
    max-width: 90%;
    width: 800px;
}
